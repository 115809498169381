<template>
  <div>
    <section class="spesh" v-if="!straightToPai">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-3">
            <img
              class="cat-img"
              src="https://shop.imperiumng.com/assets/images/complete.png"
              alt=""
            />
          </div>
          <div class="col-md-6 center-text">
            <h1 class="categoryHeadd f-bold text-capitalize">Power Plans</h1>
            <p class="mb-0 text-dark para">
              Check out our various renewable energy solutions and energy saving
              equipments with comfortable financing plans.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="products" v-if="!straightToPai">
      <div class="container">
        <div class="row align-items-stretch justify-content-center">
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs2.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/2.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  AC200
                  <div class="cap">2KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs2.monthly) }}</div>
                  <div class="pera">Monthly for 24 months</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 8X </span>
                    <span>LED lights 4W</span>
                  </div>
                  <div class="appl down">
                    <div class="count">
                      {{ numberFormat(shs2.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div>
                  <!-- <div class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(shs2  .without_solar) }}
                    </div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="shs2.active"
                    @click="
                      getStarted(
                        'shs2',
                        shs2.monthly,
                        shs2.downpayment,
                        (shs2.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs3.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  AC300
                  <div class="cap">3KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs3.monthly) }}</div>
                  <div class="pera">Monthly for 24 months</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl down">
                    <div class="count">
                      {{ numberFormat(shs3.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div>
                  <!-- <div class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(shs3  .without_solar) }}
                    </div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="shs3.active"
                    @click="
                      getStarted(
                        'shs3',
                        shs3.monthly,
                        shs3.downpayment,
                        (shs3.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="shs33.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  Solar Home System
                  <div class="cap">3KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(shs33.monthly) }}</div>
                  <div class="pera">Monthly for 24 months</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <!-- <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div> -->
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl down">
                    <div class="count">
                      {{ numberFormat(shs33.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div>
                  <!-- <div class="appl down">
                    <div class="">
                      Price without Solar Panel (Inverter System)
                    </div>
                    <div class="count">
                      {{ numberFormat(shs33.without_solar) }}
                    </div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="shs33.active"
                    @click="
                      getStarted(
                        'shs33',
                        shs33.monthly,
                        shs33.downpayment,
                        (shs33.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerup.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/1KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerup
                  <div class="cap">1KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerup.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 6X </span>
                    <span>LED Lights 4W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerup.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="powerup.active"
                    @click="
                      getStarted(
                        'powerup',
                        powerup.monthly,
                        powerup.downpayment,
                        (powerup.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerlite.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/1.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerlite
                  <div class="cap">
                    1.5 <br />
                    KVA
                  </div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerlite.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 32'Inch</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 8X </span>
                    <span>LED lights 4W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerlite.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="powerlite.active"
                    @click="
                      getStarted(
                        'powerlite',
                        powerlite.monthly,
                        powerlite.downpayment,
                        (powerlite.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerplus.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/2.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerplus
                  <div class="cap">2.5 <br />KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerplus.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Fans - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Tabletop Fridge - 50W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 10X </span>
                    <span>LED lights - 8W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerplus.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="powerplus.active"
                    @click="
                      getStarted(
                        'powerplus',
                        powerplus.monthly,
                        powerplus.downpayment,
                        (powerplus.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerxtra.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerxtra
                  <div class="cap">3KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerxtra.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerxtra.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>
                <div class="sbt">
                  <button
                    v-if="powerxtra.active"
                    @click="
                      getStarted(
                        'powerxtra',
                        powerxtra.monthly,
                        powerxtra.downpayment,
                        (powerxtra.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerxtraplus.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerxtraplus
                  <div class="cap">3.3 <br />KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">
                    {{ numberFormat(powerxtraplus.outright) }}
                  </div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerxtraplus.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>
                <div class="sbt">
                  <button
                    v-if="powerxtraplus.active"
                    @click="
                      getStarted(
                        'powerxtraplus',
                        powerxtraplus.monthly,
                        powerxtraplus.downpayment,
                        (powerxtraplus.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerultra.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/3.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerultra
                  <div class="cap">3.5 <br />KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerultra.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Sound System - 40W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerultra.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>
                <div class="sbt">
                  <button
                    v-if="powerultra.active"
                    @click="
                      getStarted(
                        'powerultra',
                        powerultra.monthly,
                        powerultra.downpayment,
                        (powerultra.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerflex.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerflex
                  <div class="cap">5KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerflex.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 4X </span>
                    <span>Fan - 60W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Set</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Inverter A/C - 1HP</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Freezer - 200W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fridge - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Microwave - 500W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>TV - 42 Inches</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerflex.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="powerflex.active"
                    @click="
                      getStarted(
                        'powerflex',
                        powerflex.monthly,
                        powerflex.downpayment,
                        (powerflex.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powerpro.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/7.5KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powerpro
                  <div class="cap">
                    7.5 <br />
                    KVA
                  </div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powerpro.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Freezer - 250W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Fridge - 100W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 4X </span>
                    <span>Standing Fan - 80W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Inverter A/C - 1HP</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>TV Sets - 42 Inches</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Microwave - 500W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Washing Machine - 1000W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 20X </span>
                    <span>LED Points - 5W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 2X </span>
                    <span>Decoder Sets</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span>TVs - 50"/42"/32" sets</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Pumping Machine - 1.5HP</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powerpro.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="powerpro.active"
                    @click="
                      getStarted(
                        'powerpro',
                        powerpro.monthly,
                        powerpro.downpayment,
                        (powerpro.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-sm-12 col-12"
            v-if="powermax.active"
          >
            <div class="product">
              <div class="tp">
                <img src="@/assets/images/10KVA.jpeg" class="w-100" alt="" />

                <div class="ovl">
                  powermax
                  <div class="cap">10KVA</div>
                </div>
              </div>
              <div class="bt">
                <div class="prch">
                  <div class="prc">{{ numberFormat(powermax.outright) }}</div>
                  <div class="pera">Outright</div>
                </div>
                <div class="applg">
                  <h6>
                    Recommended <br />
                    Load:
                  </h6>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Freezer - 350W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Decoder </span>
                  </div>
                  <div class="appl">
                    <span class="count"> 3X </span>
                    <span
                      >Inverter A/C - 1HP <span class="count">x1</span>, 2HP
                      <span class="count">x2</span></span
                    >
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Refrigerator - 200W</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Pumping Machine - 1.5HP</span>
                  </div>
                  <div class="appl">
                    <span class="count"> 1X </span>
                    <span>Washing Machine - 1000W</span>
                  </div>
                  <!-- <div class="appl down">
                    <div class="count">
                      {{ numberFormat(powermax.downpayment) }}
                    </div>
                    <div class="">Down Payment</div>
                  </div> -->
                </div>

                <div class="sbt">
                  <button
                    v-if="powermax.active"
                    @click="
                      getStarted(
                        'powermax',
                        powermax.monthly,
                        powermax.downpayment,
                        (powermax.outright * 0.9).toFixed(2)
                      )
                    "
                  >
                    Get Started
                  </button>
                  <button disabled v-else>Out of Stock</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 text-left">
          <div class="flex">
            <div class="warnCont">
              <img src="@/assets/images/warn.png" class="warn" alt="" />
            </div>
            <p class="terms">
              Prices are updated monthly and may vary in response to current
              market conditions
            </p>
          </div>

          <div class="flex">
            <div class="warnCont">
              <img src="@/assets/images/warn.png" class="warn" alt="" />
            </div>
            <p class="terms">Terms and conditions apply.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="blend"></section>
    <section class="modal" v-if="openModal" @click.self="closeModal">
      <div class="inr container">
        <div class="clbtn">
          <span class="bt" @click="closeModal">
            <span></span>
            <span></span>
          </span>
        </div>
        <div>
          <h4>{{ product }}</h4>
          <p class="subt mt-0 mb-0 w-100 text-center">
            fill in your data, and our representative will reach out to you
          </p>
        </div>
        <div>
          <form class="row mt-4">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Full Name / Business Name"
                  v-model="full_name"
                  class="form-control"
                />
                <p class="err">{{ nameErr }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Phone Number"
                  v-model="phone_number"
                  class="form-control"
                />
                <p class="err">{{ phoneErr }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Email Address (Optional)"
                  v-model="email"
                  class="form-control"
                />
                <p class="err">{{ mailErr }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <select
                  placeholder="How did you hear about us?"
                  v-model="media"
                  class="form-control"
                >
                  <option value="" disabled selected>
                    How did you hear about us?
                  </option>
                  <option value="Tbh">TBH</option>
                  <!-- <option value="Switch">Switch</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Facebook">Facebook</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Twitter">Twitter</option>
                  <option value="YouTube">YouTube</option>
                  <option value="Nairaland">Nairaland</option> -->
                </select>
                <p class="err">{{ mediaErr }}</p>
              </div>
            </div>
            <div class="col-12" v-if="valid">
              <div class="row">
                <div class="col-12"><p>How would you like to pay?</p></div>
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <button
                    class="w-100 text-center mt-2 outline-none"
                    @click.prevent="Submit"
                  >
                    Pay Outright
                  </button>
                </div>
                <div class="col-md-3"></div>
                <!-- <div class="col-md-6">
                  <button
                    class="w-100 text-center mt-2 outline-none"
                    @click.prevent="pai"
                  >
                    Pay in Installments
                  </button>
                </div> -->
              </div>
            </div>
            <div class="col-12">
              <p class="suc" v-if="success">Request Submitted Successfully!</p>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="loading" v-if="loading">Please Wait...</section>
    <section class="loading" style="text-align: center" v-if="uploading">
      Uploading file...
    </section>
    <section class="installment modal" v-if="installment">
      <div class="inr container">
        <div class="overf">
          <div class="clbtn" v-if="!straightToPai">
            <span class="bt" @click="closeModal">
              <span></span>
              <span></span>
            </span>
          </div>
          <div>
            <h4>{{ product }}</h4>
            <!-- <p class="subt mt-0 mb-0 w-100 text-center"> -->

            <form>
              <div class="row" v-if="paiStage === 0">
                <div class="col-12">
                  <p><strong> Applying as:</strong></p>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group item-center">
                        <label for="cat1">Individual</label>
                        <input
                          type="radio"
                          name="category"
                          v-model="ins.category"
                          value="individual"
                          id="cat1"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group item-center">
                        <label for="cat2">Business</label>
                        <input
                          type="radio"
                          name="category"
                          v-model="ins.category"
                          value="business"
                          id="cat2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6"></div>
                <div class="col-2"></div>
                <div class="col-md-4">
                  <button
                    class="w-100 m-0"
                    @click.prevent="paiStage = 1"
                    v-if="ins.category"
                  >
                    NEXT
                  </button>
                </div>
              </div>
              <div class="row" v-if="paiStage === 1">
                <div class="col-12" v-if="!straightToPai">
                  <div class="row">
                    <div class="col-6 col-lg-4">
                      <p class="m-0">
                        {{ isBusiness ? "Business Name" : "Name" }}
                      </p>
                      <p>
                        <strong>{{ this.full_name }}</strong>
                      </p>
                    </div>
                    <div class="col-6 col-lg-4">
                      <p class="m-0">Email Address</p>
                      <p>
                        <strong>{{ this.email }}</strong>
                      </p>
                    </div>
                    <div class="col-6 col-lg-4">
                      <p class="m-0">Phone Number</p>
                      <p>
                        <strong>{{ this.phone_number }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <p class="m-4 text-center" v-if="straightToPai">
                    Please fill all fields in the form below
                  </p>
                  <p class="m-4 text-center" v-else>
                    We will be needing more information
                  </p>
                </div>
                <div class="col-12" v-if="straightToPai">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>{{
                          isBusiness ? "Business Name" : "Full Name"
                        }}</label>
                        <input
                          type="text"
                          :placeholder="
                            isBusiness ? 'Business Name' : 'Full Name'
                          "
                          v-model="full_name"
                          class="form-control"
                        />
                        <p class="err">{{ nameErr }}</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          placeholder="Phone Number"
                          v-model="phone_number"
                          class="form-control"
                        />
                        <p class="err">{{ phoneErr }}</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Email Address</label>
                        <input
                          type="text"
                          placeholder="Email Address"
                          v-model="email"
                          class="form-control"
                        />
                        <p class="err">{{ mailErr }}</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>How did you hear about us?</label>
                        <select
                          placeholder="How did you hear about us?"
                          v-model="media"
                          class="form-control"
                        >
                          <option value="" disabled selected>
                            How did you hear about us?
                          </option>
                          <option value="Tbh">TBH</option>
                          <!-- <option value="Switch">Switch</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Facebook">Facebook</option>
                          <option value="WhatsApp">WhatsApp</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Twitter">Twitter</option>
                          <option value="YouTube">YouTube</option>
                          <option value="Nairaland">Nairaland</option> -->
                        </select>
                        <p class="err">{{ mediaErr }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>Preferred Product</label>
                        <select
                          placeholder="Preferred Product"
                          v-model="product"
                          @input="setProduct($event.target.value)"
                          class="form-control"
                        >
                          <option value="" disabled selected>
                            Preferred Product
                          </option>
                          <option value="powerup">
                            powerup @ {{ numberFormat(powerup.outright) }}
                          </option>
                          <option value="powerlite">
                            powerlite @
                            {{ numberFormat(powerlite.outright) }}
                          </option>
                          <option value="powerplus">
                            powerplus @
                            {{ numberFormat(powerplus.outright) }}
                          </option>
                          <option value="powerxtra">
                            powerxtra @
                            {{ numberFormat(powerxtra.outright) }}
                          </option>
                          <option value="powerultra">
                            powerultra @
                            {{ numberFormat(powerultra.outright) }}
                          </option>
                          <option value="powerflex">
                            powerflex @
                            {{ numberFormat(powerflex.outright) }}
                          </option>
                          <option value="powerpro">
                            powerpro @ {{ numberFormat(powerpro.outright) }}
                          </option>
                          <option value="powermax">
                            powermax @ {{ numberFormat(powermax.outright) }}
                          </option>
                        </select>
                        <p class="err">{{ mediaErr }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>Name of Director 1</label>
                  <input
                    type="text"
                    placeholder="Enter name"
                    class="form-control"
                    v-model="ins.director_name1"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>Name of Director 2</label>
                  <input
                    type="text"
                    placeholder="Enter name"
                    class="form-control"
                    v-model="ins.director_name2"
                  />
                </div>
                <div class="form-group col-12">
                  <label>{{ isBusiness ? "Office" : "Home" }} Address</label>
                  <input
                    type="text"
                    :placeholder="
                      isBusiness ? 'Office Address' : 'Home Address'
                    "
                    class="form-control"
                    v-model="ins.home_address"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isIndividual">
                  <label>BVN</label>
                  <input
                    type="number"
                    placeholder="Enter correct 11-digit BVN"
                    class="form-control"
                    v-model="ins.bvn"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>BVN of Director 1</label>
                  <input
                    type="number"
                    placeholder="Enter correct 11-digit BVN"
                    class="form-control"
                    v-model="ins.bvn1"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>BVN of Director 2</label>
                  <input
                    type="number"
                    placeholder="Enter correct 11-digit BVN"
                    class="form-control"
                    v-model="ins.bvn2"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>Tax Identificaion Number</label>
                  <input
                    type="text"
                    placeholder="Enter correct tax ID"
                    class="form-control"
                    v-model="ins.tax_id"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>Number of years in operation</label>
                  <input
                    type="number"
                    placeholder=""
                    class="form-control"
                    v-model="ins.years_in_operation"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>Number of outlet (branches)</label>
                  <input
                    type="number"
                    placeholder=""
                    class="form-control"
                    v-model="ins.number_of_outlets"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isBusiness">
                  <label>Number of employees</label>
                  <input
                    type="number"
                    placeholder=""
                    class="form-control"
                    v-model="ins.number_of_employees"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isIndividual">
                  <label>Employer's Name</label>
                  <input
                    type="text"
                    placeholder="Employer's Name"
                    class="form-control"
                    v-model="ins.employer"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isIndividual">
                  <label>Job position/role</label>
                  <input
                    type="text"
                    placeholder="Job position/role"
                    class="form-control"
                    v-model="ins.role"
                  />
                </div>
                <div class="form-group col-md-6" v-if="isIndividual">
                  <label>Date of employment</label>
                  <input
                    type="date"
                    placeholder="Date of employment"
                    class="form-control"
                    v-model="ins.date_of_employment"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>{{
                    isBusiness
                      ? "Net Monthly Income"
                      : "Net Monthly income/Salary"
                  }}</label>
                  <input
                    type="number"
                    :placeholder="
                      isBusiness
                        ? 'Net Monthly Income'
                        : 'Net Monthly income/Salary'
                    "
                    class="form-control"
                    v-model="ins.salary"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    placeholder="Bank"
                    class="form-control"
                    v-model="ins.bank"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Account Number</label>
                  <input
                    type="text"
                    placeholder="Enter correct 10-digit account number"
                    class="form-control"
                    v-model="ins.account_number"
                  />
                </div>
                <div class="col-md-6">
                  <label v-if="!isSterling"
                    >You will be required to have a Sterling Bank account, for
                    the completion of the process. If you don't have one yet, no
                    worries. You can open one after completing the form. <br />
                    <span
                      style="text-decoration: underline; cursor: pointer"
                      @click="isSterling = true"
                      >Provide Sterling Current Account number</span
                    >
                  </label>
                  <label v-if="isSterling"
                    >Sterling Current Account Number
                    <span
                      style="text-decoration: underline; cursor: pointer"
                      @click="
                        isSterling = false;
                        ins.sterling_account = '';
                      "
                      >Cancel</span
                    >
                  </label>
                  <input
                    v-if="isSterling"
                    type="text"
                    placeholder="Enter correct 10-digit account number"
                    class="form-control"
                    v-model="ins.sterling_account"
                  />
                </div>
                <div class="col-12">
                  <p v-if="this.ins.other_bank_loans.length">
                    <strong
                      >Existing loan{{
                        this.ins.other_bank_loans.length > 1 ? "s" : ""
                      }}</strong
                    >
                  </p>
                  <div v-if="ins.other_bank_loans.length">
                    <p
                      v-for="(loan, index) in ins.other_bank_loans"
                      class="m-0"
                      :key="index"
                    >
                      {{ numberFormat(parseInt(loan.amount)) }} at
                      {{ loan.bank_name }}
                      <span
                        style="
                          text-decoration: underline;
                          cursor: pointer;
                          display: inline-block;
                          margin-left: 10px;
                        "
                        @click="removeLoan(index)"
                        >Remove</span
                      >
                    </p>
                  </div>

                  <label
                    >Do you have
                    {{ this.ins.other_bank_loans.length ? "more" : "" }}
                    existing loan(s)?
                    <span
                      style="text-decoration: underline; cursor: pointer"
                      @click="addDetail = true"
                      >Add
                      {{
                        this.ins.other_bank_loans.length ? "More" : "Details"
                      }}</span
                    >
                  </label>
                  <div class="row items-end mb-6" v-if="addDetail">
                    <div class="col-md-4">
                      <label>Bank Name</label>
                      <input
                        class="form-control"
                        placeholder="Bank Name"
                        type="text"
                        v-model="one.bank_name"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>Amount</label>
                      <input
                        class="form-control"
                        placeholder="Amount"
                        type="number"
                        v-model="one.amount"
                      />
                    </div>
                    <div class="col-md-2 col-6">
                      <div class="w-100 fend">
                        <button class="w-100 m-0" @click.prevent="addToDetail">
                          Add
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2 col-6">
                      <div class="w-100 fend">
                        <button
                          class="w-100 m-0"
                          @click.prevent="
                            addDetail = false;
                            one = { bank_name: '', amount: '' };
                          "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6"></div>
                <div class="col-2"></div>
                <div class="col-md-4">
                  <button
                    class="w-100 m-0"
                    @click.prevent="moveToAttachments"
                    v-if="
                      isIndividual
                        ? ismoveToAttachments
                        : ismoveToAttachmentsBusiness
                    "
                  >
                    NEXT
                  </button>
                </div>
              </div>
              <div class="row" v-if="paiStage === 2">
                <div class="col-12">
                  <p style="text-align: center" class="m-0">
                    <strong>Attachments</strong>
                  </p>
                  <p style="text-align: center">Please upload as specified</p>
                </div>
                <div class="col-md-6 mb-4">
                  <label for=""
                    >{{
                      isIndividual
                        ? "Last 6 months Bank Statement of account"
                        : "Last 12 months Bank Statement of account "
                    }}
                  </label>
                  <p v-if="ins.attachments.account_statement_url">
                    File attached
                    <span
                      @click="ins.attachments.account_statement_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    class="form-control"
                    @input="upload($event, false, 'account_statement_url')"
                    type="file"
                    v-else
                  />
                </div>
                <div class="col-md-6 mb-4" v-if="isIndividual">
                  <label for="">Copy of employment letter </label>
                  <p v-if="ins.attachments.employment_letter_url">
                    File attached
                    <span
                      @click="ins.attachments.employment_letter_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    class="form-control"
                    @input="upload($event, false, 'employment_letter_url')"
                    type="file"
                    v-else
                  />
                </div>
                <div class="col-md-6 mb-4" v-if="isIndividual">
                  <label for="">Valid employment ID </label>
                  <p v-if="ins.attachments.employment_id_url">
                    File attached
                    <span
                      @click="ins.attachments.employment_id_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    class="form-control"
                    @input="upload($event, false, 'employment_id_url')"
                    type="file"
                    v-else
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <label for="">Signature (PNG / JPG)</label>
                  <p v-if="ins.attachments.signature_url">
                    File attached
                    <span
                      @click="ins.attachments.signature_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    v-else
                    class="form-control"
                    @input="upload($event, true, 'signature_url')"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </div>
                <!-- BUSINESS ONLY ATTACHMENTS -->
                <div class="col-md-6 mb-4" v-if="isBusiness">
                  <label for="">Certificate of Incorporation</label>
                  <p v-if="ins.attachments.certificate_of_incorporation_url">
                    File attached
                    <span
                      @click="
                        ins.attachments.certificate_of_incorporation_url = ''
                      "
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    v-else
                    class="form-control"
                    @input="
                      upload($event, false, 'certificate_of_incorporation_url')
                    "
                    type="file"
                  />
                </div>
                <div class="col-md-6 mb-4" v-if="isBusiness">
                  <label for="">Memorandum and Article of Association </label>
                  <p v-if="ins.attachments.memorandum_url">
                    File attached
                    <span
                      @click="ins.attachments.memorandum_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    v-else
                    class="form-control"
                    @input="upload($event, false, 'memorandum_url')"
                    type="file"
                  />
                </div>
                <div class="col-md-6 mb-4" v-if="isBusiness">
                  <label for="">Director </label>
                  <p v-if="ins.attachments.director_url">
                    File attached
                    <span
                      @click="ins.attachments.director_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    v-else
                    class="form-control"
                    @input="upload($event, false, 'director')"
                    type="file"
                  />
                </div>
                <div class="col-md-6 mb-4" v-if="isBusiness">
                  <label for="">Shareholding </label>
                  <p v-if="ins.attachments.shareholding_url">
                    File attached
                    <span
                      @click="ins.attachments.shareholding_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    v-else
                    class="form-control"
                    @input="upload($event, false, 'shareholding_url')"
                    type="file"
                  />
                </div>
                <div class="col-md-6 mb-4" v-if="isBusiness">
                  <label for=""
                    >Three (3) year’s audited financials for corporates (LTD and
                    PLC) Inclusive of Statement of Cashflow
                  </label>
                  <p v-if="ins.attachments.financials_url">
                    File attached
                    <span
                      @click="ins.attachments.financials_url = ''"
                      style="
                        display: inline-block;
                        margin-left: 10px;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >Remove</span
                    >
                  </p>
                  <input
                    v-else
                    class="form-control"
                    @input="upload($event, false, 'financials')"
                    type="file"
                  />
                </div>

                <div class="col-md-8"></div>
                <div
                  class="col-md-4"
                  v-if="
                    (isIndividual &&
                      ins.attachments.account_statement_url &&
                      ins.attachments.employment_letter_url &&
                      ins.attachments.employment_id_url &&
                      ins.attachments.signature_url) ||
                    (isBusiness &&
                      ins.attachments.account_statement_url &&
                      ins.attachments.certificate_of_incorporation_url &&
                      ins.attachments.memorandum_url &&
                      ins.attachments.director_url &&
                      ins.attachments.shareholding_url &&
                      ins.attachments.financials_url &&
                      ins.attachments.signature_url)
                  "
                >
                  <button class="w-100" @click.prevent="paiStage = 3">
                    Next
                  </button>
                </div>
              </div>
              <div class="w-full row align-items-center" v-if="paiStage === 3">
                <div class="col-md-6">
                  <letter-of-application
                    :uname="full_name"
                    :sign="sign"
                    :price="numberFormat(outright)"
                    :downpayment="numberFormat(downpayment)"
                    :product="product"
                    naira="₦"
                    :ins="ins"
                    :existing="existingLoans"
                    :download="acknowledged"
                    :show="acknowledge"
                    :isBusiness="isBusiness"
                    :isIndividual="isIndividual"
                    :director1="ins.director_name1"
                    :director2="ins.director_name2"
                  />
                </div>
                <div class="col-md-6 actions">
                  <div class="fcenter">
                    <input type="checkbox" v-model="acknowledge" id="sign" />
                    <label for="sign"> Sign Letter </label>
                  </div>
                  <div class="fcenter" v-if="acknowledge">
                    <input type="checkbox" v-model="consent" id="consent" />
                    <label for="consent">
                      By submitting, you consent, acknowledge, and authorize us
                      to collect your Credit Bureau Data and bank records for
                      the purposes of credit processing.
                      <br />
                      Check out our
                      <router-link to="/privacy" target="_blank">
                        <strong style="color: #255e13">
                          privacy policy</strong
                        ></router-link
                      >
                      for more information.
                    </label>
                  </div>
                  <button
                    @click.prevent="signAndSubmit"
                    v-if="acknowledge && consent"
                  >
                    Submit
                  </button>
                  <div v-if="success && !isSterling">
                    <p>
                      Request submitted Successfully. Click
                      <a
                        href="https://sterlingonlineaccount.sterling.ng/#/GetStarted"
                        style="color: #255e13"
                        target="_blank"
                        ><strong>here</strong></a
                      >
                      to create a Sterling Bank account
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import prices from "@/assets/json/prices.json";
import LetterOfApplication from "../components/LetterOfApplication.vue";
export default {
  components: { LetterOfApplication },
  data() {
    return {
      ...prices,
      // powerup: {
      //   // 1KVA
      //   monthly: 52883.31,
      //   downpayment: 153153.38,
      //   outright: 1176971.25,
      // },
      // powerlite: {
      //   // 1.5 KVA
      //   monthly: 81951.91,
      //   downpayment: 237337.87,
      //   outright: 1823922.19,
      // },
      // powerplus: {
      //   monthly: 89813.96,
      //   downpayment: 260106.86,
      //   outright: 1998900.0,
      // },
      // powerxtra: {
      //   // 3 KVA
      //   monthly: 94060.90,
      //   downpayment: 272406.28,
      //   outright: 2093420.0,
      // },
      // powerultra: {
      //   // 3.5 KVA
      //   monthly: 142431.22,
      //   downpayment: 412489.74,
      //   outright: 3169950.0,
      // },
      // powerflex: {
      //   // 5 KVA
      //   monthly: 201687.13,
      //   downpayment: 584098.59,
      //   outright: 4488750.0,
      // },
      // powerpro: {
      //   // 7.5 KVA
      //   monthly: 344239.66,
      //   downpayment: 996939.68,
      //   outright: 7661400.0,
      // },
      // powermax: {
      //   // 10 KVA
      //   monthly: 407222.66,
      //   // downpayment: 8466586.46,
      //   // downpayment: 1227447.33,
      //   downpayment: 1179342.39,
      //   outright: 9063150.0,
      // },
      acknowledge: false,
      isSterling: false,
      consent: false,
      acknowledged: false,
      openModal: false,
      product: "",
      price: 0,
      outright: 0,
      downpayment: 0,
      full_name: "",
      email: "",
      media: "Tbh",
      phone_number: "",
      nameErr: "",
      phoneErr: "",
      mailErr: "",
      mediaErr: "",
      loading: false,
      uploading: false,
      success: false,
      installment: false,
      addDetail: false,
      paiStage: 0,
      sign: "",
      one: { bank_name: "", amount: "" },
      ins: {
        category: "",
        sterling_account: "",
        home_address: "",
        bvn: "",
        employer: "",
        role: "",
        date_of_employment: "",
        salary: "",
        bank: "",
        account_number: "",
        other_bank_loans: [],
        //business only
        director_name1: "",
        director_name2: "",
        bvn1: "",
        bvn2: "",
        tax_id: " ",
        years_in_operation: "",
        number_of_outlets: "",
        number_of_employees: "",

        attachments: {
          account_statement_url: "",
          employment_letter_url: "",
          employment_id_url: "",
          signature_url: "",
          //Business Only
          certificate_of_incorporation_url: "",
          memorandum_url: "",
          director_url: "", //Key might change.. or maybe not
          shareholding_url: "", //Key might change.. or maybe not
          financials_url: "",
        },
      },
    };
  },
  computed: {
    isIndividual() {
      return this.ins.category === "individual";
    },
    isBusiness() {
      return this.ins.category === "business";
    },
    straightToPai() {
      if (this.$route.path === "/apply") {
        // console.log(this.$route.query.mode);
        return true;
      } else {
        return false;
      }
    },
    fromMedia() {
      return this.capitalizeFirstLetter(this.$route.query.media || "");
    },
    valid() {
      var valid = false;
      var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.full_name.length) {
        valid = false;
      } else if (this.full_name.length < 2) {
        valid = false;
      } else if (this.phone_number.length < 11) {
        valid = false;
      } else if (this.email.length && !this.email.match(validRegex)) {
        valid = false;
      } else if (this.media === "") {
        valid = false;
      } else {
        valid = true;
      }
      return valid;
    },
    ismoveToAttachments() {
      if (
        this.valid &&
        this.ins.home_address.length > 10 &&
        this.ins.bvn.length === 11 &&
        this.ins.employer.length &&
        this.ins.role.length &&
        this.ins.date_of_employment &&
        this.ins.salary &&
        this.ins.bank &&
        this.ins.account_number.length === 10 &&
        (!this.isSterling ||
          (this.isSterling && this.ins.sterling_account.length === 10))
      ) {
        return true;
      } else {
        return false;
      }
    },
    ismoveToAttachmentsBusiness() {
      if (
        this.valid &&
        this.ins.home_address.length > 10 &&
        this.ins.director_name1.length >= 3 &&
        this.ins.director_name2.length >= 3 &&
        this.ins.bvn1.length === 11 &&
        this.ins.bvn2.length === 11 &&
        this.ins.tax_id.length &&
        this.ins.years_in_operation &&
        this.ins.number_of_outlets &&
        this.ins.number_of_employees &&
        this.ins.salary &&
        this.ins.bank &&
        this.ins.account_number.length === 10 &&
        (!this.isSterling ||
          (this.isSterling && this.ins.sterling_account.length === 10))
      ) {
        return true;
      } else {
        return false;
      }
    },
    existingLoans() {
      var sum = 0,
        banks = "";
      this.ins.other_bank_loans.forEach((loan, index) => {
        sum += parseInt(loan.amount);
        banks = banks + (index > 0 ? ", " : "") + loan.bank_name.trim();
      });
      banks = banks + ".";
      return { banks: banks, amount: this.numberFormat(sum) };
    },
  },
  methods: {
    capitalizeFirstLetter(str) {
      // Check if the input is not an empty string
      if (str.length === 0) {
        return str;
      }

      // Capitalize the first letter and concatenate the rest of the string
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    setProduct(product) {
      switch (product) {
        case "powerup":
          this.product = product;
          this.price = this.powerup.monthly;
          this.downpayment = this.powerup.downpayment;
          this.outright = (this.powerup.outright * 0.9).toFixed(2);

          break;
        case "powerlite":
          this.product = product;
          this.price = this.powerlite.monthly;
          this.downpayment = this.powerlite.downpayment;
          this.outright = (this.powerlite.outright * 0.9).toFixed(2);

          break;
        case "powerplus":
          this.product = product;
          this.price = this.powerplus.monthly;
          this.downpayment = this.powerplus.downpayment;
          this.outright = (this.powerplus.outright * 0.9).toFixed(2);

          break;
        case "powerxtra":
          this.product = product;
          this.price = this.powerxtra.monthly;
          this.downpayment = this.powerxtra.downpayment;
          this.outright = (this.powerxtra.outright * 0.9).toFixed(2);

          break;
        case "powerultra":
          this.product = product;
          this.price = this.powerultra.monthly;
          this.downpayment = this.powerultra.downpayment;
          this.outright = (this.powerultra.outright * 0.9).toFixed(2);

          break;
        case "powerflex":
          this.product = product;
          this.price = this.powerflex.monthly;
          this.downpayment = this.powerflex.downpayment;
          this.outright = (this.powerflex.outright * 0.9).toFixed(2);

          break;
        case "powerpro":
          this.product = product;
          this.price = this.powerpro.monthly;
          this.downpayment = this.powerpro.downpayment;
          this.outright = (this.powerpro.outright * 0.9).toFixed(2);

          break;
        case "powermax":
          this.product = product;
          this.price = this.powermax.monthly;
          this.downpayment = this.powermax.downpayment;
          this.outright = (this.powermax.outright * 0.9).toFixed(2);

          break;

        default:
          break;
      }
    },
    signAndSubmit() {
      // this.acknowledged = true
      this.loading = true;
      var data = JSON.stringify({
        ...this.ins,
        name: this.full_name,
        phone_number: this.phone_number,
        email: this.email,
        product: this.product,
        price: this.outright,
        monthly_repayment: this.price,
        downpayment: this.downpayment,
        media: this.media,
      });
      var ebod = this.$helpers.encrypt(data);

      // console.log(ebod);

      var bod = JSON.stringify({ data: ebod });
      fetch(`${process.env.VUE_APP_API_URL}/newsales/v2`, {
        method: "post",
        body: bod,
        headers: {
          "Content-Type": "Application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.data);
          data = this.$helpers.decrypt(data.data);
          data = JSON.parse(data);
          // console.log(data);

          if (data.status === "success") {
            // console.log(data);
            // document.getElementById("dlink").click();
            this.loading = false;
            this.acknowledged = true;
            this.$toast.success(
              "Pay in installment",
              "Details submitted",
              this.$toastPosition
            );
            this.$toast.info(
              "Pay in installment",
              "Downloading signed document, please wait for download",
              this.$toastPosition
            );
            this.success = true;
            // this.full_name = "";
            // this.email = "";
            // this.media = "";
            // this.phone_number = "";
            // this.ins = {
            //   home_address: "",
            //   bvn: "",
            //   employer: "",
            //   role: "",
            //   date_of_employment: "",
            //   salary: "",
            //   bank: "",
            //   account_number: "",
            //   other_bank_loans: [],
            //   attachments: {
            //     account_statement_url: "",
            //     employment_letter_url: "",
            //     employment_id_url: "",
            //     signature_url: "",
            //   },
            // };
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      // console.log(data);
    },
    numberFormat(x) {
      if (typeof x !== "number") {
        return "";
      }
      var newNum = Math.ceil(x);
      return "₦" + newNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    upload(e, imageOnly, identifier) {
      var type = e.target.files[0].type.toLowerCase();
      var name = e.target.files[0].name;
      var size = e.target.files[0].size; //1.2mb =1,200,000

      var valid = false;
      if (imageOnly) {
        if (
          type !== "image/jpeg" &&
          type !== "image/jpg" &&
          type !== "image/png"
        ) {
          this.$toast.info(
            "File upload",
            "Invalid file type",
            this.$toastPosition
          );
          e.target.value = "";
          valid = false;
          return false;
        } else {
          valid = true;
        }
      } else {
        valid = true;
      }
      // if (size > 250000) {
      //   this.$store.dispatch(
      //     'alert/error',
      //     'File Too Large. Maximum file size 250kb',
      //     {
      //       root: true,
      //     }
      //   )
      //   e.target.value = ''
      //   return false
      // } else {
      if (valid) {
        this.uploading = true;
        // //console.log('uploading')
        var ufile = e.target.files[0];

        var nfile = new FormData();
        // //console.log(nfile)
        nfile.append("file", ufile);
        // //console.log(nfile)
        let data = nfile;

        // //console.log(data)

        // return
        // path: '/uploads/get-url',

        // axios({
        //   url: `${config.baseURL}/uploads/get-url`,
        //   // headers: authHeader(),
        //   data,
        //   method: "POST",
        //   // timeout: 6000,
        // })
        fetch(`${process.env.VUE_APP_API_URL}/uploads/get-url`, {
          method: "POST",
          body: data,
          // headers: {
          //   "Content-Type": "Application/json",
          // },
        })
          .then((response) => response.json())
          .then((resp) => {
            // //console.log(resp);
            let decr = JSON.parse(this.$helpers.decrypt(resp.data));
            resp = decr;
            // console.log(resp);
            // //console.log(resp);
            if (resp.status === "success") {
              // this.credit_bureau_report = resp.data
              // var tempObj = {
              //   name: this.temp_name,
              //   url: resp.data,
              // }

              var doc = resp.data;

              switch (identifier) {
                case "account_statement_url":
                  this.ins.attachments.account_statement_url = doc;

                  break;
                case "employment_letter_url":
                  this.ins.attachments.employment_letter_url = doc;

                  break;
                case "employment_id_url":
                  this.ins.attachments.employment_id_url = doc;

                  break;
                case "signature_url":
                  {
                    this.sign = URL.createObjectURL(ufile);
                    this.ins.attachments.signature_url = doc;
                  }
                  break;

                case "certificate_of_incorporation_url":
                  this.ins.attachments.certificate_of_incorporation_url = doc;
                  break;

                case "memorandum_url":
                  this.ins.attachments.memorandum_url = doc;
                  break;

                case "director":
                  this.ins.attachments.director_url = doc;
                  break;

                case "shareholding_url":
                  this.ins.attachments.shareholding_url = doc;
                  break;

                case "financials":
                  this.ins.attachments.financials_url = doc;
                  break;

                default:
                  break;
              }

              this.uploading = false;
              e.target.value = "";
              this.$toast.success(
                "File upload",
                "File uploaded",
                this.$toastPosition
              );
              e.target.value = "";
              this.uploading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(
              "File upload",
              "There was an error uploading your file",
              this.$toastPosition
            );
            this.uploading = false;
            e.target.value = "";
          });
      } else {
        return false;
      }
    },
    moveToAttachments() {
      if (
        (this.isIndividual && this.ismoveToAttachments) ||
        (this.isBusiness && this.ismoveToAttachmentsBusiness)
      ) {
        this.paiStage = 2;
      }
    },
    removeLoan(indexToRemove) {
      if (
        indexToRemove >= 0 &&
        indexToRemove < this.ins.other_bank_loans.length
      ) {
        this.ins.other_bank_loans.splice(indexToRemove, 1);
      }
    },
    addToDetail() {
      if (!this.one.bank_name) {
        this.$toast.info(
          "Existing Loan",
          "Please provide bank name",
          this.$toastPosition
        );
        return false;
      } else if (!this.one.amount) {
        this.$toast.info(
          "Existing Loan",
          "Please provide loan amount",
          this.$toastPosition
        );
        return false;
      } else {
        this.ins.other_bank_loans.push(this.one);
        this.one = { bank_name: "", amount: "" };
        this.addDetail = false;
      }
    },
    pai() {
      var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.email.length || !this.email.match(validRegex)) {
        this.$toast.info(
          "Pay in installment",
          "Valid email is required for installmental payments",
          this.$toastPosition
        );
      } else {
        this.openModal = false;
        this.installment = true;
      }
    },
    closeModal() {
      this.openModal = false;
      this.paiStage = 0;
      this.installment = false;
      this.product = "";
      this.price = 0;
      this.downpayment = 0;
      this.email = "";
      this.media = "";
      this.phone_number = "";
      this.full_name = "";
      this.nameErr = "";
      this.phoneErr = "";
      this.mailErr = "";
      this.mediaErr = "";
      this.success = false;
      this.ins = {
        category: "",
        home_address: "",
        bvn: "",
        employer: "",
        role: "",
        date_of_employment: "",
        salary: "",
        bank: "",
        account_number: "",
        other_bank_loans: [],
        //business only
        director_name1: "",
        director_name2: "",
        bvn1: "",
        bvn2: "",
        tax_id: " ",
        years_in_operation: "",
        number_of_outlets: "",
        number_of_employees: "",

        attachments: {
          account_statement_url: "",
          employment_letter_url: "",
          employment_id_url: "",
          signature_url: "",
          //Business Only
          certificate_of_incorporation_url: "",
          memorandum_url: "",
          director_url: "", //Key might change.. or maybe not
          shareholding_url: "", //Key might change.. or maybe not
          financials_url: "",
        },
      };
    },
    getStarted(product, price, down, outright) {
      if (product) {
        this.product = product;
        this.price = price;
        this.outright = outright;
        this.downpayment = down;
        this.openModal = true;
      } else {
        return false;
      }
    },
    Submit() {
      var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      this.nameErr = "";
      this.phoneErr = "";
      this.mailErr = "";
      this.mediaErr = "";
      if (!this.full_name.length) {
        this.nameErr = "Please Enter your name";
        return false;
      } else if (this.full_name.length < 2) {
        this.nameErr = "Please provide a valid name";
      } else if (this.phone_number.length < 11) {
        this.phoneErr = "Please input a valid phone number";
      } else if (this.email.length && !this.email.match(validRegex)) {
        this.mailErr = "Please input a valid mail or leave the field empty";
      } else if (this.media === "") {
        this.mediaErr = "Please select an option";
      } else {
        this.loading = true;
        // return
        let bod = JSON.stringify({
          full_name: this.full_name,
          phone_number: this.phone_number,
          email: this.email,
          product: this.product,
          price: this.price,
          monthly_repayment: this.price,
          downpayment: this.downpayment,
          media: this.media,
        });
        // console.log(bod);
        // console.log({
        //   full_name: this.full_name,
        //   phone_number: this.phone_number,
        //   email: this.email,
        //   product: this.product,
        // });
        // console.log(bod);
        // return
        var ebod = this.$helpers.encrypt(bod);

        // console.log(ebod);

        bod = JSON.stringify({ data: ebod });

        // console.log(bod);

        // console.log(process.env.VUE_APP_API_URL);
        // console.log(bod);
        fetch(`${process.env.VUE_APP_API_URL}/newsales`, {
          method: "post",
          body: bod,
          headers: {
            "Content-Type": "Application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data.data);
            data = this.$helpers.decrypt(data.data);
            data = JSON.parse(data);
            // console.log(data);

            if (data.status === "success") {
              // console.log(data);
              // document.getElementById("dlink").click();
              this.loading = false;
              this.success = true;
              this.full_name = "";
              this.email = "";
              this.media = "";
              this.phone_number = "";
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    // console.log()
    if (this.$route.path === "/apply") {
      this.installment = true;
    }
    // if (this.fromMedia) {
    //   this.media = this.fromMedia;
    // }
  },
};
</script>
<style>
</style>
<style lang="scss" scoped >
.flex {
  display: flex;
  column-gap: 0.5rem;
  align-items: flex-start;
  .warnCont {
    // background: #c4c4c4;
    border-radius: 100%;
    width: 25px;
    img {
      width: 25px;
    }
  }
}
.overf {
  max-height: 90vh;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    /* @apply bg-black rounded-md; */
    background-color: #fdd826;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    /* border: 3px solid; */
    background-color: #255e1388;
    border-radius: 20px;
    /* @apply bg-black border-red rounded-md cursor-pointer; */
  }
}
.pdf-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* div {
    width: 49%;
  }
  button {
    width: 200px;
  } */
}
.actions {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  button {
    width: 200px;
  }
}
.fend {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.fcenter {
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  gap: 10px;
  * {
    margin: 0;
  }
  input {
    cursor: pointer;
    width: 14px;
    height: 24px;
  }
}
.item-center {
  display: flex;
  align-items: center;
  gap: 1rem;
  label,
  input {
    cursor: pointer;
  }
  * {
    margin: 0;
  }
}
.suc {
  color: #255e13;
  font-weight: 600;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4c4c488;
  backdrop-filter: blur(3px);
  z-index: 999999999;
  font-weight: 600;
}
.err {
  color: red;
  font-size: 0.8rem;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21965388;
  z-index: 9999999;
  backdrop-filter: blur(3px);
  .inr {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    min-width: 300px;
    .clbtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 4px;
      .bt {
        display: inline-block;
        width: 26px;
        height: 26px;
        position: relative;
        cursor: pointer;
        span {
          position: absolute;
          /* border-radius: 2px; */
          top: 13px;
          left: 0;
          height: 2px;
          background-color: #000;
          width: 100%;
          display: block;
          &:nth-child(even) {
            transform: rotate(45deg);
          }
          &:nth-child(odd) {
            transform: rotate(-45deg);
          }
        }
      }
    }
    h4 {
      /* font-size: 47.6654px; */
      color: #255e13;
      font-weight: 700;
      text-align: center;
    }
    .subt {
      width: 80%;
      margin: auto;
    }
    @media (max-width: 450px) {
      max-width: 320px;
      .subt {
        width: 100%;
      }
    }
    form {
      button {
        border: none;
        border-radius: 0.25rem;
        background-color: #255e13;
        color: #fdd826;
        font-weight: 900;
        padding: 10px 0;
        outline: none;
        transition: all 0.3s ease-out;
        &:hover {
          background-color: #fdd826;
          color: #255e13;
        }
      }
    }
  }
}
.w-100 {
  width: 100%;
}
.spesh {
  background: linear-gradient(
    180deg,
    #f4fcf2 3.01%,
    rgba(204, 222, 171, 0.1607843137)
  );
  background-size: cover;
  padding: 0;
}
.cat-img {
  height: 240px;
}
.center-text {
  text-align: center !important;
}
.categoryHeadd {
  font-size: 47.6654px;
  line-height: 52px;
  color: #255e13;
  font-weight: 700;
}
.products {
  background-color: rgba(204, 222, 171, 0.1607843137);
  padding: 10vh 0;
  .product {
    margin-top: 30px;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    img.w-100 {
      width: 100%;
    }
    .tp {
      background-color: #fff;
      position: relative;
      .ovl {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 60%, #cbdfab);
        display: flex;
        align-items: flex-end;
        padding: 8px;
        font-size: 1.4rem;
        color: #219653;
        font-weight: 800;
        .cap {
          position: absolute;
          bottom: 8px;
          right: 8px;
          width: 60px;
          height: 60px;
          color: #fff;
          background-color: #219653;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          font-weight: 800;
          text-align: center;
          line-height: 1;
        }
      }
    }
    .bt {
      background-color: #cbdfab;
      border-top: 2px solid #fff;
      padding: 8px;
      position: relative;
      .prch {
        position: absolute;
        top: -2px;
        right: 0;
        .prc {
          color: #fdd826;
          font-size: 1.2rem;
          text-align: center;
          font-weight: 800;
          background-color: #255e13;
          padding: 4px 8px;
        }
        .pera {
          padding: 0 6px;
          font-size: 0.6rem;
          background-color: #219653;
          color: #fff;
          text-align: center;
        }
      }
      .applg {
        min-height: 22vh;
        h6 {
          font-weight: 600;
          color: #000;
        }
        .down {
          position: relative;
          /* width: min-content; */
          font-weight: 600;
          margin-top: 1rem;
          /* top: -2px; */
          /* right: 0; */
          /* .prc {
            color: #fdd826;
            font-size: 1.2rem;
            text-align: center;
            font-weight: 800;
            background-color: #255e13;
            padding: 4px 8px;
          }
          .pera {
            padding: 0 6px;
            font-size: 0.6rem;
            background-color: #219653;
            color: #fff;
          } */
        }
      }
      .appl {
        display: flex;
        column-gap: 4px;
        color: #000;
        font-size: 0.85rem;

        .count {
          color: #255e13;
        }
      }
      .sbt {
        /* padding: 0 4px; */
        margin-top: 2rem;
        button {
          width: 100%;
          border: none;
          background-color: #255e13;
          color: #fdd826;
          font-weight: 900;
          padding: 6px 0;
          transition: all 0.3s ease-out;
          &:hover {
            background-color: #fdd826;
            color: #255e13;
          }
          &:disabled {
            color: #255e13;
            cursor: not-allowed;
            background: #f4fcf2;
          }
        }
      }
    }
  }
}
.blend {
  height: 5vh;
  background: linear-gradient(
    180deg,
    rgba(204, 222, 171, 0.1607843137),
    rgba(241, 252, 237, 0)
  );
}
.terms {
  font-size: 1.1rem;
  font-weight: 600;
  //new chanfe
}
</style>